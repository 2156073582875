import {useState} from "react";
import {Modal, ModalContent} from "../modal/Modal";

const Portfolios = (props) => {
    const [isOpen, setIsOpen] = useState(false);
    const [portfolioImage, setPortfolioImage] = useState(null);
    const showModal = () => setIsOpen((prev) => !prev);

    return (
        <div className="container mx-auto py-[160px]">
            <div className="text-center">
                <h1 className="text-[28px] text-[#000] font-semibold md:text-[42px] uppercase morisonDisplay"><span
                    className="text-[#C59D5F] morisonDisplay">Wszystkie</span> tatuaże</h1>
            </div>

            <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-[35px] py-[80px] ">

                {props?.images?.map((portfolioItem) => (
                    <Modal onOpen={showModal}>
                        <div className="holder w-full h-auto aspect-square" onClick={() => setPortfolioImage(portfolioItem.attributes.media.data.attributes.formats.medium.url)}>
                            <img src={portfolioItem.attributes.media.data.attributes.formats.small.url} alt="" className="w-full h-full object-cover"/>
                        </div>
                    </Modal>

                ))}
            </div>


            {isOpen && (
                <ModalContent onClose={() => setIsOpen(false)}>
                    <div className="w-full h-full flex items-center justify-center">
                        <img src={portfolioImage} alt="" className="  "/>
                    </div>
                </ModalContent>
            )}

        </div>
    )
}
export default Portfolios;