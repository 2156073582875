const options = {
    year: 'numeric',
    month: 'numeric',
    day: 'numeric',
    hour: 'numeric',
    minute: 'numeric',
    second: 'numeric',
};


const Card = (props) => {
    return (
        <div className="">
            <div className="w-full h-[300px]">
                <img src={`${props.image}`} alt="" className="w-full h-full object-cover"/>
            </div>
            <h1 className="text-[18px] font-poppins font-semibold text-[#2F2F2F] mt-[30px] md:text-[25px]">{props.title}</h1>
            <p className="mt-[20px] text-[14px] font-medium text-[#2f2f2fb3] md:text-[16px]">{props.description}</p>
            <div className="flex items-center justify-between mt-[30px]">
                <p className="text-[#2f2f2f80] text-[14px] font-medium leading-[137%]">{new Intl.DateTimeFormat('pl', options).format(new Date(props.createdAt))}</p>
                <p className="text-[16px] text-[#C59D5F] underline font-semibold">Czytaj dalej</p>

            </div>
        </div>
    )
}

export default Card;