import {useState} from "react";

const Item = ({question, answer}) => {
    const [isOpen, setIsOpen] = useState(false);

    return (
        <div
            className="px-[20px] py-[20px] md:py-[30px] md:px-[30px] lg:py-[35px] border border-[#21212180] rounded-[3px] cursor-pointer"
            onClick={() => setIsOpen(!isOpen)}>
            <div className="flex items-center justify-between ">
                <p className="text-[#212121b3] text-[14px]  md:text-[18px] font-semibold">{question}</p>
                <p className="text-[#212121b3] text-[13px] font-semibold">+</p>
            </div>
            {isOpen && <div className="mt-[20px]">{answer}</div>}
        </div>
    )
}

export default Item;