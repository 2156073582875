import {CustomChat, FacebookProvider} from "react-facebook";


const MessengerChatPlugin = () => {
    return (
        <FacebookProvider appId="2261182620758565" chatSupport>
            <CustomChat pageId="125348328140617" minimized="true"/>
        </FacebookProvider>
    );
};

export default MessengerChatPlugin;