import {HashLink} from "react-router-hash-link";
import {useState} from "react";
import {Modal, ModalContent} from "../../modal/Modal";

const Portfolio = (props) => {
    const [isOpen, setIsOpen] = useState(false);
    const [modalImage, setModalImage] = useState(null);
    const showModal = () => setIsOpen((prev) => !prev);

    console.log(props);

    return (
        <section id="portfolio">
            <div className="py-[80px]">
                <div className="text-center">
                    <h1 className="text-[28px] text-[#000] font-semibold md:text-[42px] uppercase morisonDisplay">Zobacz
                        nasze <span className="text-[#C59D5F] morisonDisplay">portfolio</span></h1>
                    <p className="text-[14px] text-[#000000b3] md:text-[18px]">Sprawdź nasze najnowsze realizacje</p>
                </div>

                <div className="container mx-auto grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-[35px] mt-[80px] ">
                    {
                        props?.portfolioItems?.slice(0, 8).map((portfolioItem) => (

                            <Modal onOpen={showModal}>
                                <div className="holder w-full h-auto aspect-square" onClick={() => {
                                    setModalImage(portfolioItem.attributes.media?.data.attributes.formats.medium.url)
                                }}>
                                    <img src={portfolioItem.attributes.media?.data.attributes.formats.small.url} alt="" className="w-full h-full object-cover"/>
                                </div>
                            </Modal>

                        ))
                    }
                </div>

                <div className="flex items-center justify-center mt-[80px]">
                    <HashLink to="/wszystkie-realizacje/#">
                        <button
                            className="p-[20px] lg:py-[24px] lg:px-[27px] bg-[#C59D5F] rounded-[3px] text-[13px] lg:text-[16px] text-[#FFF] lg:text-[#FBFBFB] font-medium leading-[100%]">Wszystkie
                            prace
                        </button>
                    </HashLink>
                </div>

            </div>


            {isOpen && (
                <ModalContent onClose={() => setIsOpen(false)}>
                    <div className="w-full h-full flex items-center justify-center">
                        <img src={modalImage} alt="" className="  "/>
                    </div>
                </ModalContent>
            )}


        </section>
    )
}

export default Portfolio;