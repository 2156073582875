const Info = () => {
    return (
        <div className=" text-center md:ml-[30px] lg:ml-[60px] lg:text-left">

            <h1 className="text-[28px] lg:text-[42px] text-[#000000b3] uppercase morisonDisplay"><span
                className="text-[#C59D5F] morisonDisplay">Napisz</span> do nas</h1>
            <p className="my-[50px] text-[14px] lg:text-[18px] font-poppins text-[#000000b3] font-medium lg:my-[60px]">Jesteśmy gotowi, by zrealizować Twoją wizję artystycznego tatuażu! Skorzystaj z poniższych informacji, aby nawiązać z nami kontakt bądź użyj formularza kontaktowego:</p>

            <div className="">
                <div className="flex items-center justify-center lg:justify-start py-[17px]">
                    <svg xmlns="http://www.w3.org/2000/svg" width="19" height="20" viewBox="0 0 19 20" fill="none">
                        <path
                            d="M18.4582 13.5491L14.3018 11.7678C14.1243 11.6922 13.9269 11.6762 13.7395 11.7224C13.5521 11.7686 13.3848 11.8744 13.2628 12.0239L11.4221 14.2728C8.53335 12.9108 6.20858 10.586 4.84657 7.69724L7.09544 5.85658C7.24524 5.73474 7.35127 5.56741 7.39747 5.37992C7.44368 5.19243 7.42754 4.995 7.3515 4.8175L5.57021 0.661177C5.48676 0.469841 5.33916 0.313621 5.15286 0.219456C4.96656 0.12529 4.75324 0.099081 4.54969 0.145348L0.690246 1.03599C0.493997 1.08131 0.318903 1.19181 0.193542 1.34945C0.0681805 1.5071 -4.52078e-05 1.70258 2.24746e-08 1.90399C2.24746e-08 11.4227 7.71517 19.123 17.219 19.123C17.4205 19.1232 17.6161 19.055 17.7738 18.9296C17.9315 18.8043 18.0421 18.6291 18.0874 18.4328L18.9781 14.5734C19.024 14.3688 18.9973 14.1546 18.9024 13.9677C18.8075 13.7808 18.6504 13.6327 18.4582 13.5491Z"
                            fill="#C59D5F"/>
                    </svg>
                    <p className="text-[#212121b3] text-[13px] lg:text-[13px] lg:text-[16px] font-poppins ml-[20px]">
                        +48 606 285 158
                    </p>
                </div>
                <div className="flex  justify-center lg:justify-start py-[17px]">
                    <svg xmlns="http://www.w3.org/2000/svg" width="19" height="16" viewBox="0 0 19 16" fill="none">
                        <path
                            d="M18.64 5.07617C18.7848 4.95508 19 5.06836 19 5.25977V13.248C19 14.2832 18.2021 15.123 17.2188 15.123H1.78125C0.797852 15.123 0 14.2832 0 13.248V5.26367C0 5.06836 0.211523 4.95898 0.359961 5.08008C1.19121 5.75977 2.29336 6.62305 6.07852 9.51758C6.86152 10.1191 8.18262 11.3848 9.5 11.377C10.8248 11.3887 12.1719 10.0957 12.9252 9.51758C16.7104 6.62305 17.8088 5.75586 18.64 5.07617ZM9.5 10.123C10.3609 10.1387 11.6004 8.98242 12.2238 8.50586C17.1482 4.74414 17.523 4.41602 18.6586 3.47852C18.8738 3.30273 19 3.0293 19 2.74023V1.99805C19 0.962891 18.2021 0.123047 17.2188 0.123047H1.78125C0.797852 0.123047 0 0.962891 0 1.99805V2.74023C0 3.0293 0.126172 3.29883 0.341406 3.47852C1.47695 4.41211 1.85176 4.74414 6.77617 8.50586C7.39961 8.98242 8.63906 10.1387 9.5 10.123Z"
                            fill="#C59D5F"/>
                    </svg>
                    <p className="text-[#212121b3] text-[13px] lg:text-[16px] font-poppins ml-[20px]">
                        kontakt@habitattattoo.pl
                    </p>
                </div>
                <div className="flex  justify-center lg:justify-start py-[17px]">
                    <svg xmlns="http://www.w3.org/2000/svg" width="19" height="26" viewBox="0 0 19 26" fill="none">
                        <path
                            d="M10.6727 24.5613C13.2109 21.4184 19 13.801 19 9.52237C19 4.33316 14.7448 0.123047 9.5 0.123047C4.25521 0.123047 0 4.33316 0 9.52237C0 13.801 5.78906 21.4184 8.32734 24.5613C8.93594 25.3103 10.0641 25.3103 10.6727 24.5613ZM9.5 6.38926C10.3399 6.38926 11.1453 6.71936 11.7392 7.30693C12.333 7.8945 12.6667 8.69142 12.6667 9.52237C12.6667 10.3533 12.333 11.1502 11.7392 11.7378C11.1453 12.3254 10.3399 12.6555 9.5 12.6555C8.66015 12.6555 7.85469 12.3254 7.26083 11.7378C6.66696 11.1502 6.33333 10.3533 6.33333 9.52237C6.33333 8.69142 6.66696 7.8945 7.26083 7.30693C7.85469 6.71936 8.66015 6.38926 9.5 6.38926Z"
                            fill="#C59D5F"/>
                    </svg>
                    <p className="text-[#212121b3] text-[13px] lg:text-[16px] font-poppins ml-[20px]">
                        Cmentarna 11, 88-170 Pakość
                    </p>
                </div>

            </div>
        </div>
    )
}

export default Info;
