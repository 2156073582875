import {HashLink} from "react-router-hash-link";

const Card = (props) => {
    return (
        <div className="container mx-auto flex flex-col py-[46px]">
            <div className="flex items-center justify-center">
                <img src={props.image} alt={props?.title +"'s image"} className="object-cover h-12 w-12"/>
            </div>
            <h1 className="text-[#212121] text-[16px] font-poppins font-semibold text-center my-[30px] md:text-[25px]">{props?.title}</h1>
            <p className="font-medium font-poppins text-[14px] text-center text-[#212121b3] md:text-[18px]">{props?.description}</p>
            <div className="flex items-center justify-center mt-[50px]">
                    <HashLink target="_blank" to={props.href??"https://www.instagram.com/pthabitat/"}>            
                <button
                    className="border border-[#C59D5F] text-black py-[20px] px-[27px] lg:py-[24px] lg:px-[36px] rounded-[3px] text-[13px] lg:text-[16px] font-poppins text-[#FFF] font-medium">
                    Dowiedz się więcej
                </button>
            </HashLink>
            </div>
        </div>
    )
}

export default Card;
