import Left from "./left/Left";
import Right from "./right/Right";

const AboutUs = () => {
    return (
        <section id="o-nas">
            <div className="container mx-auto py-[25px] lg:py-[160px]">
                <div className="grid grid-cols-1 lg:grid-cols-2 lg:gap-[50px]">
                    <div className="lg:order-first ">
                        <Left/>
                    </div>
                    <div className="order-first my-[25px] lg:my-0">
                        <Right/>
                    </div>
                </div>
            </div>
        </section>
    )
}

export default AboutUs;