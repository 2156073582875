import Card from "./card/Card";
import image from "../../../assets/blog/blog3.svg"
import {useEffect, useState} from "react";
import axios from "axios";
import {useParams} from "react-router-dom";

const Article = (props) => {

    let { id } = useParams();

    // const { hash } = useLocation();
    const [articles, setArticles] = useState([]);

    useEffect(() => {
        const fetchArticles = async () => {
            try {
                const response = await axios.get(`https://api.habitattattoo.pl/api/posts/${id}?populate=contents.image`);
                const data = response.data.data.attributes?.contents;
                setArticles(data);
            } catch (error) {
                console.error("Error fetching posts:", error);
            }
        };

        fetchArticles();
    }, []);


    return (
        <div className="container mx-auto py-[160px]">
            <div className="grid grid-cols-1 gap-[150px] lg:gap-[100px]">
                {articles?.map((article, index) => (
                    <Card key={index} title={article?.title} description={article?.description} index={index} image={article.image.data.attributes.formats.medium.url}/>
                ))}
            </div>
        </div>
    )
}

export default Article;