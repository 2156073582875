import {useForm} from "react-hook-form";
import {Turnstile} from "@marsidev/react-turnstile";
import {useEffect, useRef, useState} from "react";
import axios from "axios";

const Form = () => {

    const {
        register,
        handleSubmit,
        setValue,
        // watch,
        formState: {errors},
    } = useForm()

    const [status, setStatus] = useState({state: "IDLE"});

    const recaptchaRef = useRef(null);

    useEffect(() => {
        const response = recaptchaRef.current?.getResponse()
        setValue('recaptcha', response);
    }, [recaptchaRef.current]);
    const validateCaptcha = () => {
        const response = recaptchaRef.current?.getResponse()
        setValue('recaptcha', response);
    }

    const onSubmit = async (data) => {
        // console.log(data);
        await axios.post('https://api.habitattattoo.pl/api/contact', data).then((res) => {
            if (res.data.success) {
                setStatus({
                    state: "SUCCESS",
                    message: "Wiadomość wysłana"
                });
            } else {
                setStatus({
                    state: "ERROR",
                    message: res.data.message
                });
            }
        }).catch(() => {
            setStatus({
                state: "ERROR",
                message: "Wystąpił błąd z wysyłaniem wiadomości"
            });
        }).finally(() => {
            recaptchaRef.current.reset();
        });
    }

    return (
        <form onSubmit={handleSubmit(onSubmit)}
              className="bg-[#FFF] p-[30px] lg:p-[50px] shadow-2xl md:mr-[30px] lg:mr-[60px] mt-[30px] lg:mt-0">
            <div className="py-[9px]">
                <input type="text" {...register("fullName", {
                    required: true
                })} placeholder="Imie i nazwisko"
                       required={true}
                       className="w-full h-[55px] px-[25px] border border-[#212121] rounded-[3px]"/>
            </div>
            <div className="py-[9px]">
                <input type="email" {...register("email", {
                    required: true
                })} placeholder="Email"
                       required={true}
                       className="w-full h-[55px] px-[25px] border border-[#212121] rounded-[3px]"/>
            </div>
            <div className="py-[9px]">
                <input type="text" {...register("topic", {
                    required: true
                })} placeholder="Temat"
                       required={true}
                       className="w-full h-[55px] px-[25px] border border-[#212121] rounded-[3px]"/>
            </div>

            <div className="py-[9px]">
                <textarea
                    {...register("message", {
                        required: true
                    })}
                    required={true}
                    placeholder="Wiadomość"

                    className="w-full h-[160px] p-[25px] border border-[#212121] rounded-[3px]"/>
            </div>

            <div className="py-[9px] flex items-start">
                <input type="checkbox" id="checkbox" required={true} {...register("checkbox", {
                    required: true
                })} />
                <label htmlFor="checkbox" className="text-xs text-black/80 ml-[10px] font-poppins font-medium">
                    Wypełniając i przesyłając formularz niniejszym wyraża Pan/Pani zgodę na przetwarzanie swoich danych
                    osobowych przez Pracownie Tatuażu HABITAT jako administratora danych osobowych oraz akceptuje Pan/Pani
                    regulamin zawarty na stronie www.
                </label>
            </div>
            <div className="flex flex-col xl:flex-row items-center justify-between mt-[27px]">
                <Turnstile options={{
                    theme: 'light'
                }}
                           onAfterInteractive={validateCaptcha}
                           onError={validateCaptcha}
                           onSuccess={validateCaptcha}
                           onExpire={validateCaptcha}
                           onBeforeInteractive={validateCaptcha}
                           ref={recaptchaRef}
                           siteKey='0x4AAAAAAAO-M9nVVBAl0ogl'
                />
                {status.state === "SUCCESS" && <button
                    disabled={true}
                    className="py-[20px] px-[27px] lg:py-[24px] lg:px-[36px] bg-green-500 rounded-[3px] text-[13px] lg:text-[16px] font-poppins text-[#FFF] font-medium w-full mt-[20px] xl:mt-0 xl:w-auto">
                    {status.message}
                </button>}
                {(status.state === "IDLE" || status.state === "ERROR") && <button
                    type="submit"
                    className="py-[20px] px-[27px] lg:py-[24px] lg:px-[36px] bg-[#C59D5F] rounded-[3px] text-[13px] lg:text-[16px] font-poppins text-[#FFF] font-medium w-full mt-[20px] xl:mt-0 xl:w-auto">
                    Wyślij wiadomość
                </button>}

            </div>
            {(errors || status.state === "ERROR") && (
                <div>
                    {errors.recaptcha && <p className="text-[#FF0000]">Captcha jest wymagana!</p>}
                    {status.state === "ERROR" && <p className="text-[#FF0000]">{status.message}</p>}
                </div>
            )}

        </form>
    )
}

export default Form;