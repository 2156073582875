import {HashLink} from "react-router-hash-link";
import {useEffect, useState} from "react";
import {AiOutlineClose} from "react-icons/ai";
import logoLight from "../../assets/navbarLogoLight.svg"
import logoDark from "../../assets/navbarLogoDark.svg"

const links = [
    {
        title: "O nas",
        link: "/#o-nas"
    },
    {
        title: "Oferta",
        link: "/#oferta"
    },
    {
        title: "Realizacje",
        link: "/#portfolio"
    },
    {
        title: "Blog",
        link: "/#blog"
    },
    {
        title: "FAQ",
        link: "/#faq"
    },
]

const Navbar = (props) => {

    const [nav, setNav] = useState(false);
    const [scrolling, setScrolling] = useState(false)

    // const handleNav = () => {
    //     setNav(!nav);
    // };

    const handleScroll = () => {
        if (window.scrollY > 60) {
            setScrolling(true);
        } else {
            setScrolling(false)
        }
    }

    useEffect(() => {
        window.addEventListener("scroll", handleScroll)

        return () => {
            window.removeEventListener("scroll", handleScroll)
        }

    }, [])

    return (
        <div
            className={scrolling ? `shadow-2xl navbar fixed z-[500] bg-[#212121] top-0 text-[#fbfbfbb3] w-full h-[100px]` : `fixed navbar z-[500] top-0 text-[${props?.color}] w-full h-[100px]`}>
            <div className="container mx-auto w-full">

                <div className="flex items-center justify-between h-[100px] lg:hidden">
                    <HashLink to="/#">
                        <img src={scrolling ? logoLight : props?.logo} alt="" className="h-[45px]"/>
                    </HashLink>
                    <svg xmlns="http://www.w3.org/2000/svg" width="28" height="16" viewBox="0 0 28 16" fill="none"
                         onClick={() => setNav(!nav)}>
                        <rect width="28" height="2" rx="1"
                              fill={scrolling ? props?.hamburgerScroll : props?.hamburgerStatic}/>
                        <rect y="7" width="28" height="2" rx="1"
                              fill={scrolling ? props?.hamburgerScroll : props?.hamburgerStatic}/>
                        <rect y="14" width="28" height="2" rx="1"
                              fill={scrolling ? props?.hamburgerScroll : props?.hamburgerStatic}/>
                    </svg>
                </div>

                <div className="flex items-center justify-center h-[100px] hidden lg:flex gap-[50px]">
                    <HashLink to="/#o-nas">
                        <p className="text-[16px] font-poppins font-medium leading-[137%]">O nas</p>
                    </HashLink>
                    <HashLink to="/#oferta">
                        <p className="text-[16px] font-poppins font-medium leading-[137%]">Oferta</p>
                    </HashLink>
                    <HashLink to="/#portfolio">
                        <p className="text-[16px] font-poppins font-medium leading-[137%]">Realizacje</p>
                    </HashLink>
                    <HashLink to="/#">
                        <img src={scrolling ? logoLight : props?.logo} alt="Logo" className="h-[45px] mx-[25px]"/>
                    </HashLink>
                    <HashLink to="/#blog">
                        <p className="text-[16px] font-poppins font-medium leading-[137%]">Blog</p>
                    </HashLink>
                    <HashLink to="/#faq">
                        <p className="text-[16px] font-poppins font-medium leading-[137%]">FAQ</p>
                    </HashLink>
                    <HashLink to="/#kontakt">
                        <p className="text-[16px] font-poppins font-medium leading-[137%]">Skontaktuj się</p>
                    </HashLink>
                </div>
            </div>

            {
                nav && (
                    <div className="fixed top-0 left-0 w-full h-full bg-[#FFF] py-[60px] overflow-y-scroll">
                        <div className="container mx-auto h-full w-full">


                            <div className="flex items-center justify-between ">
                                <HashLink to="/#">
                                    <img src={logoDark} alt="Logo" className="h-[45px]"/>
                                </HashLink>
                                <AiOutlineClose size={30} className="text-[#000] cursor-pointer"
                                                onClick={() => setNav(false)}/>
                            </div>

                            <div className="h-full w-full flex items-center justify-center flex-col gap-[50px]">
                                {links.map((link) => (
                                    <HashLink to={link?.link} onClick={()=> setNav(false)}>
                                        <p className={`text-[16px] text-[#000] font-poppins font-medium leading-[137%] hover:text-[#C59D5F] hover:font-semibold `}>
                                            {link.title}
                                        </p>
                                    </HashLink>
                                ))}

                                <HashLink to="/#kontakt">
                                    <button
                                        className="py-[20px] px-[24px] bg-[#C59D5F] rounded-[3px] text-[13px] text-[#FFF] font-medium">
                                        Skontaktuj się
                                    </button>
                                </HashLink>
                            </div>

                        </div>
                    </div>
                )
            }

        </div>

        // <div className={scrolling ? `shadow-2xl navbar w-full fixed z-[500] bg-[#212121] top-0 text-[#fbfbfbb3]` : `fixed navbar w-full z-[500] top-0 text-[${props?.color}]`}>
        //     <div className='container mx-auto py-[20px] flex justify-between items-center w-full'>
        //         <div className="w-full flex items-center justify-between lg:justify-center">
        //             <div className="block lg:hidden">
        //                 <HashLink>
        //                     <img src={scrolling ? props?.logo : logoLight} alt="Logo" className="h-[52px] w-[39px]"/>
        //                 </HashLink>
        //             </div>
        //             <ul className={`hidden lg:flex lg:items-center text-[13px] `}>
        //                 <HashLink to="/#o-nas">
        //                     <li className="mx-[25px] text-[16px] font-poppins font-medium leading-[137%]">O nas</li>
        //                 </HashLink>
        //                 <HashLink to="/#oferta" >
        //                     <li className="mx-[25px] text-[16px] font-poppins font-medium leading-[137%]">Oferta</li>
        //                 </HashLink>
        //                 <HashLink to="/#portfolio">
        //                     <li className="mx-[25px] text-[16px] font-poppins font-medium leading-[137%]">Realizacje</li>
        //                 </HashLink>
        //                 <HashLink to="/#">
        //                     <img src={scrolling ? logoLight : props?.logo} alt="Logo" className="h-[52px] w-[39px] mx-[25px]"/>
        //                 </HashLink>
        //                 <HashLink to="/#blog">
        //                     <li className="mx-[25px] text-[16px] font-poppins font-medium leading-[137%]">Blog</li>
        //                 </HashLink>
        //                 <HashLink to="/#faq">
        //                     <li className="mx-[25px] text-[16px] font-poppins font-medium leading-[137%]">FAQ</li>
        //                 </HashLink>
        //                 <HashLink to="/#kontakt">
        //                     <li className="mx-[25px] text-[16px] font-poppins font-medium leading-[137%]">Skontaktuj się</li>
        //                 </HashLink>
        //             </ul>
        //
        //             <div onClick={handleNav} className='block lg:hidden'>
        //                 <AiOutlineMenu size={30} className={`text-[${props?.color}] cursor-pointer`}/>
        //             </div>
        //
        //         </div>
        //         <ul className={nav ? 'fixed left-0 top-0 w-full h-full bg-[#FFF] ease-in-out duration-500 z-[500] mx-auto py-[20px]' : 'ease-in-out duration-500 fixed left-[-100%]'} onClick={() => setNav(false)}>
        //             <div className="container flex items-center justify-between mx-auto">
        //                 <HashLink to="/#">
        //                     <img src={logoDark} alt="Logo" className="h-[52px] w-[39px]"/>
        //                 </HashLink>
        //                 <AiOutlineClose size={30} className="text-[#000] cursor-pointer" onClick={() => setNav(false)}/>
        //             </div>
        //             <div className="text-center py-[30px]">
        //                 {links.map((link) => (
        //                     <HashLink to={link?.link}>
        //                         <li className={`mx-[16px] text-[16px] text-[#000] font-poppins font-medium leading-[137%] hover:text-[#1364EE] hover:font-semibold py-[20px]`}>
        //                             {link.title}
        //                         </li>
        //                     </HashLink>
        //                 ))}
        //             </div>
        //             <div className="flex flex-col items-center">
        //                 <HashLink to="/#kontakt">
        //                     <button className="py-[20px] px-[24px] bg-[#C59D5F] rounded-[3px] text-[13px] text-[#FFF] font-medium">
        //                         Skontaktuj się
        //                     </button>
        //                 </HashLink>
        //             </div>
        //         </ul>
        //     </div>
        // </div>

    )
}

export default Navbar;