import Item from "./item/Item";

const Faq = () => {
    const faqData = [
        {question: 'Jaki jest minimalny wiek, aby móc zrobić tatuaż?', answer: 'Minimalny wiek, aby zrobić tatuaż w naszym studio, to 18 lat. Wymagane jest także przedstawienie ważnego dokumentu tożsamości.'},
        {question: 'Czy potrzebuję umówić się na wizytę, czy mogę po prostu przyjść?', answer: 'Zalecamy umawianie się na konsultację oraz sesje tatuażu z wyprzedzeniem. Dzięki temu możemy przygotować się i zapewnić Ci odpowiednią ilość czasu i przestrzeni.  '},
        {question: 'Czy muszę dostarczyć własny wzór, czy można go zaprojektować dla mnie?', answer: 'Oferujemy tworzenie dedykowanych projektów na podstawie Twoich pomysłów. Możesz przynieść własny wzór lub skonsultować się z naszym artystą, który pomoże Ci stworzyć unikalną tatuażową kompozycję.'},
        {question: 'Czy mogę wcześniej zobaczyć projekt tatuażu?', answer: 'Nie wysyłamy przed sesją wzoru tatuażu. Ewentualne poprawki wykonywane są podczas spotkania przed tatuowaniem. '},
        {question: 'Ile kosztuje tatuaż?', answer: 'Cena tatuażu zależy od wielu czynników, takich jak rozmiar, skala, miejsce na ciele, skomplikowanie wzoru itp. Najlepiej skontaktować się z nami bezpośrednio, aby omówić swoje pomysły i ustalić cenę.'},
        {question: 'Jak długo trwa sesja tatuażu?', answer: 'Czas trwania sesji tatuażu zależy od rozmiaru, skomplikowania wzoru oraz Twojej tolerancji bólu. Przeciętnie sesja może trwać od jednej do kilku godzin. Dokładny czas zostanie ustalony przed rozpoczęciem tatuażu.'},
        {question: 'Czy zakrywacie stare tatuaże?', answer: 'Tak, oferujemy wykonanie cover up tattoo, czyli kowerów. Jest to nowy tatuaż wykonany na starym. Zwykle niezbędna jest konsultacja przed umówieniem sesji, ze względu na ograniczenia związane ze starym tatuażem.'},
        {question: 'Czy muszę się czegoś wystrzegać przed wizytą w studiu tatuażu?', answer: 'Zalecamy, aby przed wizytą w studiu tatuażu unikać spożywania alkoholu, zażywania leków rozrzedzających krew oraz wystawiania skóry na długotrwałe działanie słońca.'},
        {question: 'Jak dbać o świeżo wykonany tatuaż?', answer: 'Po wykonaniu tatuażu dostaniesz instrukcje dotyczące pielęgnacji. Ogólnie zalecamy regularne mycie tatuażu delikatnym mydłem i nakładanie kremu przyspieszającego gojenie. Ważne jest również unikanie pocierania, drapania lub wystawiania tatuażu na działanie promieni słonecznych przez pierwsze kilka tygodni.'},
        {question: 'Czy tatuaż jest bolesny?', answer: 'Ból związany z tatuażem może być odczuwany różnie przez różne osoby. Często opisuje się to jako uczucie ukłucia lub drapania skóry. Nasi artyści zrobią wszystko, co w ich mocy, aby Ci pomóc i zminimalizować ewentualny dyskomfort podczas procesu tatuażowania.'},
    ];

    return (
        <section id="faq">
            <div className="container mx-auto py-[160px]">

                <div className="text-center">
                    <h1 className="text-[28px] text-[#000] font-semibold md:text-[42px] uppercase morisonDisplay">Często
                        zadawne <span className="text-[#C59D5F] morisonDisplay">pytania</span></h1>
                    <p className="text-[14px] text-[#000000b3] md:text-[18px]">Masz pytanie? Jest duża szansa, że
                        odpowiedź znajdziesz tutaj</p>
                </div>

                <div className="max-w-[900px] mx-auto mt-[100px]">
                    {faqData.map((item, index) => (
                        <div className="my-[15px]">
                            <Item key={index} question={item.question} answer={item.answer}/>
                        </div>
                    ))}
                </div>
            </div>
        </section>
    );
}

export default Faq;
