import {HashLink} from "react-router-hash-link";
// import {useState} from "react";
// import headerBackground from '../../../../public/headerBackground.svg'
// import headerBackground2 from '../../../assets/aboutUsImage.svg'
// import headerBackground3 from '../../../assets/offerCardImage.svg'

const Header = () => {

    // console.log(headerBackground);

    // const images = [
    //     headerBackground,
    //     // headerBackground2,
    //     // headerBackground3
    // ]

    // const [slider, setSlider] = useState(0);


    return (
        <div className={`h-screen min-h-[52rem] bg-header-bg bg-cover bg-no-repeat bg-center`}>
            <div className="container mx-auto h-full">
                <div className="flex items-center justify-center flex-col w-full h-full">
                    <h2 className="text-center text-[#fbfbfbcc] text-[22px] lg:text-[40px] italic morisonDisplay">Witold
                        Barcikowski</h2>
                    <h1 className="text-[#fbfbfbcc]  text-center text-[48px] lg:text-[100px] leading-[101%] lg:text-[#FBFBFB] mt-[20px] morisonDisplay">
                        Pracownia Tatuażu <br/> Habitat
                    </h1>
                    <h2 className="text-center text-[#fbfbfbcc] text-[22px] lg:text-[20px] italic morisonDisplay my-[20px]">Założone
                        w 2017 roku</h2>

                    <p className="text-[#fbfbfbcc] text-center text-[13px] my-[30px] lg:mt-[30px] max-w-[650px] lg:text-[18px] mx-auto font-poppins ">
                        Pracownia Tatuażu HABITAT w Pakości, założona w 2017 roku przez Witka Barcikowskiego, to
                        studio, które oferuje unikalne tatuaże i wiele wiecej. Zapraszamy!
                    </p>
                    <div className="flex items-center justify-center gap-[10px]">
                        <HashLink to="/#kontakt">
                            <button
                                className="cursor-pointer p-[20px] lg:py-[24px] lg:px-[27px] bg-[#C59D5F] rounded-[3px] text-[13px] lg:text-[16px] text-[#FFF] lg:text-[#FBFBFB] font-medium leading-[100%]">
                                Umów się na wizytę
                            </button>
                        </HashLink>
                        <HashLink to="/#o-nas">
                            <p className="p-[20px] lg:py-[24px] lg:px-[27px] rounded-[3px] text-[13px] lg:text-[16px] text-[#fbfbfbb3] font-medium">
                                Dowiedz się więcej
                            </p>
                        </HashLink>
                    </div>
                    <div className="lg:hidden flex items-center justify-center mt-[80px]">
                        <svg xmlns="http://www.w3.org/2000/svg" width="18" height="26" viewBox="0 0 18 26"
                             fill="none">
                            <rect x="0.75" y="0.750061" width="16.5" height="24.5" rx="8.25" stroke="white"
                                  strokeOpacity="0.7" strokeWidth="1.5"/>
                            <path d="M9 6L9 9.25" stroke="white" strokeOpacity="0.7" strokeWidth="1.5"
                                  strokeLinecap="round"/>
                        </svg>
                        <p className="max-w-[120px] text-[13px] ml-[14px] text-[#fbfbfbb3]">
                            Zjedź w dół po
                            więcej informacji
                        </p>
                    </div>
                </div>
                {/*<div className="flex items-center justify-center w-full absolute bottom-[30px] gap-[20px] z-[100]">*/}
                {/*    {*/}
                {/*        images?.map((images, index) => (*/}
                {/*            <div*/}
                {/*                className={`p-[10px] ${index === slider ? "bg-[#C59D5F] w-[16px] h-[16px]" : "bg-[#fbfbfb33] w-[8px] h-[8px]"} rounded-[100%] cursor-pointer`}*/}
                {/*                onClick={() => setSlider(index)}></div>*/}
                {/*        ))*/}
                {/*    }*/}

                {/*</div>*/}
            </div>
        </div>

    )
}

export default Header;
