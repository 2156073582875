import React, {useEffect, useState} from "react";
import Card from "../home/blog/card/Card";
import {HashLink} from "react-router-hash-link";
import axios from "axios";

const Blogs = () => {
    const [posts, setPosts] = useState([]);

    useEffect(() => {
        const fetchPosts = async () => {
            try {
                const response = await axios.get("https://api.habitattattoo.pl/api/posts?populate=*");
                const data = response.data.data;
                setPosts(data);
                console.log(data);
            } catch (error) {
                console.error("Error fetching posts:", error);
            }
        };

        fetchPosts();
    }, []); // Add fetchPosts to the dependency array to satisfy React linting rules

    return (
        <div className="container mx-auto py-[160px]">
            <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-[50px]">
                {/*{posts.map((post) => (*/}
                {/*    <HashLink key={post.id} to={`/blog/#${post.id}`}>*/}
                {/*        /!* Assuming each post has a unique ID *!/*/}
                {/*        <Card title={post.attributes.title} description={post.attributes.shortContent}*/}
                {/*              image={post.attributes.thumbnail.data.attributes.url}*/}
                {/*        createdAt={post.attributes.createdAt}/>*/}
                {/*    </HashLink>*/}
                {/*))}*/}
            </div>
        </div>
    );
};

export default Blogs;