import {HashLink} from "react-router-hash-link";
import Item from "../../faq/item/Item";

const HomeFaq = () => {
    const faqData = [
        {question: 'Jaki jest minimalny wiek, aby móc zrobić tatuaż?', answer: 'Minimalny wiek, aby zrobić tatuaż w naszym studio, to 18 lat. Wymagane jest także przedstawienie ważnego dokumentu tożsamości.'},
        {question: 'Czy potrzebuję umówić się na wizytę, czy mogę po prostu przyjść?', answer: 'Zalecamy umawianie się na konsultację oraz sesje tatuażu z wyprzedzeniem. Dzięki temu możemy przygotować się i zapewnić Ci odpowiednią ilość czasu i przestrzeni.  '},
        {question: 'Czy muszę dostarczyć własny wzór, czy można go zaprojektować dla mnie?', answer: 'Oferujemy tworzenie dedykowanych projektów na podstawie Twoich pomysłów. Możesz przynieść własny wzór lub skonsultować się z naszym artystą, który pomoże Ci stworzyć unikalną tatuażową kompozycję.'},
    ];

    return (
        <section id="faq">
            <div className="container mx-auto py-[80px]">

                <div className="text-center">
                    <h1 className="text-[28px] text-[#000] font-semibold md:text-[42px] uppercase morisonDisplay">Często
                        zadawne <span className="text-[#C59D5F] morisonDisplay">pytania</span></h1>
                    <p className="text-[14px] text-[#000000b3] md:text-[18px]">Masz pytanie? Jest duża szansa, że
                        odpowiedź znajdziesz tutaj</p>
                </div>

                <div className="max-w-[900px] mx-auto mt-[100px]">
                    {faqData.map((item, index) => (
                        <div className="my-[15px]">
                            <Item key={index} question={item.question} answer={item.answer}/>
                        </div>
                    ))}
                </div>

                <div className="flex items-center justify-center">
                    <HashLink to="/faq/#">
                        <button
                            className="py-[20px] px-[27px] lg:py-[24px] lg:px-[37px] mt-[60px] bg-[#C59D5F] rounded-[3px] text-[13px] lg:text-[16px] font-poppins text-[#FFF] font-medium">Sprawdź
                            wszystkie pytania
                        </button>
                    </HashLink>
                </div>
            </div>
        </section>
    );
}

export default HomeFaq;
