import Card from "./card/Card";

import offer1Image from "../../../assets/offer/bog.jpg"
import offer2Image from "../../../assets/offer/kot.JPG"
import offer3Image from "../../../assets/offer/ptak.JPG"
import offer4Image from "../../../assets/offer/oczy.JPG"
import offer5Image from "../../../assets/offer/oko.JPG"
import tattoo from "../../../assets/offer/tattoo.svg"
import cover from "../../../assets/offer/cover.svg"
import makeup from "../../../assets/offer/makeup.svg"
import eye from "../../../assets/offer/eye.svg"

const Offer = () => {
    return (
        <section id="oferta">
            <div className="py-[80px] lg:mt-0 overflow-hidden">
                <h1 className="uppercase mx-auto text-center text-[28px] lg:text-[42px] text-[#000] lg:font-semibold morisonDisplay">nasza <span
                    className="text-[#C59D5F] morisonDisplay">oferta</span></h1>
                <p className="text-center text-[14px] font-poppins font-medium text-[#212121b3] lg:text-[18px] mt-[25px] lg:mt-0">Sprawdź
                    co możemy dla Ciebie zrobić</p>
                <div className="grid grid-cols-1 lg:grid-cols-3 lg:mt-[100px]">

                    <div className="hidden lg:block w-full h-full aspect-[16/10]">
                        <img src={offer1Image} alt="Tatto hand" className="object-cover w-full h-full"/>
                    </div>
                    <Card title="Tatuaże"
                          image={tattoo}
                          description="Wykonujemy tatuaże w różnych tematach, stylach i technikach. Ulubiony temat to portret w technice dotwork."/>
                    <div className="hidden lg:block w-full h-full aspect-[16/10]">
                        <img src={offer2Image} alt="Tatto hand 2" className="object-cover w-full h-full"/>
                    </div>

                    <Card title="Coverup/kowery"
                          image={cover}
                          description="Mamy duże doświadczenie w rozwiązywaniu problemu niechcianego tatuażu. Skutecznie zakrywamy stare tatuaże wykonując na nich nowe."/>
                    <div className="hidden lg:block w-full h-full aspect-[16/10]">
                        <img src={offer3Image} alt="Rozwiązanie problemu z niechcianym tatuażem"
                             className="object-cover w-full h-full"/>
                    </div>
                    <Card href="https://www.instagram.com/but_first_make_up_ab?igsh=YzVkODRmOTdmMw%3D%3D" title="Wizaż"
                          image={makeup}
                          description="Wykonujemy makijaże okolicznościowe, w naturalny sposób podkreślamy urodę klientki, dobierając indywidualnie każdy kosmetyk do jej typu skóry."/>
                    <div className="hidden lg:block w-full h-full aspect-[16/10]">
                        <img src={offer4Image} alt="Tatto hand 3" className="object-cover w-full h-full"/>
                    </div>
                    <Card href="https://www.instagram.com/but_first_make_up_ab?igsh=YzVkODRmOTdmMw%3D%3D"
                          title="Stylizacja rzęs i brwi"
                          image={eye}
                          description="Chcąc wyróżnić swoje spojrzenie posiadamy usługę przedłużenia rzęs, a do podkreślenia ramy swoich oczu znajdziesz również zabieg laminacji brwi."/>
                    <div className="hidden lg:block w-full h-full aspect-[16/10]">
                        <img src={offer5Image} alt="Przedłużanie rzęs" className="object-cover w-full h-full"/>
                    </div>
                </div>
            </div>
        </section>
    )
}

export default Offer;
