import {BrowserRouter, Route, Routes} from "react-router-dom";
import Navbar from "./components/navbar/Navbar";
import HeaderSlider from "./components/home/header/Header";
import AboutUs from "./components/home/aboutus/AboutUs";
import Offer from "./components/home/offer/Offer";
import Blog from "./components/home/blog/Blog";
import Contact from "./components/contact/Contact";
import Footer from "./components/footer/Footer";
import Portfolio from "./components/home/portfolio/Portfolio";
import Faq from "./components/faq/Faq";
import Blogs from "./components/blogs/Blogs";
import Portfolios from "./components/portfolios/Portfolios";
import Article from "./components/blogs/article/Article";
import navbarLogoLight from "./assets/navbarLogoLight.svg"
import navbarLogoDark from "./assets/navbarLogoDark.svg"
import HomeFaq from "./components/home/homeFaq/HomeFaq";
import MessengerChatPlugin from "./components/messengerChat/MessengerChat";
import {useEffect, useState} from "react";
import axios from "axios";


function App() {

    const [portfolioItems, setPortfolioItems] = useState([]);

    useEffect(() => {
       fetchPortfolioItems();
    }, []);

    const fetchPortfolioItems = async () => {
        const res = await axios.get('https://api.habitattattoo.pl/api/portfolio-items?populate=media');
        const data = res.data.data;
        setPortfolioItems(data);
    }

    return (
        <div className="App">
            <BrowserRouter>
                <Routes>
                    <Route path="*" element={[
                        <Navbar color="#fbfbfbb3" logo={navbarLogoLight} hamburgerStatic="white" hamburgerScroll="white"/>,
                        <HeaderSlider/>,
                        <AboutUs/>,
                        <Offer/>,
                        <Portfolio portfolioItems={portfolioItems}/>,
                        <Blog/>,
                        <HomeFaq/>,
                        <Contact/>,
                        <Footer contact={true}/>,
                        <MessengerChatPlugin/>
                    ]}></Route>
                    <Route path="/nasz-blog" element={[
                        <Navbar color="#000" logo={navbarLogoDark} hamburgerStatic="black" hamburgerScroll="white"/>,
                        <Blogs/>,
                        <Footer contact={false}/>
                    ]}></Route>
                    <Route path="/blog/:id" element={[
                        <Navbar color="#000" logo={navbarLogoDark} hamburgerStatic="black" hamburgerScroll="white"/>,
                        <Article/>,
                        <Footer contact={false}/>
                    ]}></Route>
                    <Route path="/faq" element={[
                        <Navbar color="#000" logo={navbarLogoDark} hamburgerStatic="black" hamburgerScroll="white"/>,
                        <Faq/>,
                        <Footer contact={false}/>
                    ]}></Route>


                    <Route path="/wszystkie-realizacje"
                           element={[
                               <Navbar color="#000" logo={navbarLogoDark} hamburgerStatic="black" hamburgerScroll="white"/>,
                               <Portfolios images={portfolioItems}/>,
                               <Footer contact={false}/>
                           ]}></Route>
                </Routes>
            </BrowserRouter>
        </div>
    );
}

export default App;
