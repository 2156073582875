import Card from "./card/Card";

// import blog1Image from '../../../assets/blog/blog1.svg';
// import blog2Image from '../../../assets/blog/blog2.svg';
// import blog3Image from '../../../assets/blog/blog3.svg';
import {HashLink} from "react-router-hash-link";
import {useEffect, useState} from "react";
import axios from "axios";


const Blog = () => {

    const [posts, setPosts] = useState([]);

    useEffect(() => {
        const fetchPosts = async () => {
            try {
                const response = await axios.get("https://api.habitattattoo.pl/api/posts?populate=*");
                const data = response.data.data;
                setPosts(data);
            } catch (error) {
                console.error("Error fetching posts:", error);
            }
        };

        fetchPosts().then(() => {
            console.log(posts)
        });

    }, []); // Add fetchPosts to the dependency array to satisfy React linting rules


    return (
        <section id="blog">
            <div className="container mx-auto py-[80px]">

                <div className="flex items-center justify-between flex-col text-center md:flex-row md:text-left">
                    <div className="max-w-[500px]">
                        <h1 className="text-[28px] md:text-[42px] md:font-semibold uppercase morisonDisplay">Najnowsze
                            wpisy z <span className="text-[#C59D5F] morisonDisplay">naszego bloga</span></h1>
                        <p className="text-[14px] text-[#000000b3] md:text-[18px]">Nie przegap naszych najnowszych wpisów na naszym blogu.</p>
                    </div>
                    <HashLink to="/nasz-blog/#">
                        <button
                            className="py-[20px] px-[27px] lg:py-[24px] lg:px-[36px] bg-[#C59D5F] rounded-[3px] text-[13px] lg:text-[16px] font-poppins text-[#FFF] font-medium my-[20px] md:my-0">Zobacz
                            wszystkie posty
                        </button>
                    </HashLink>

                </div>

                <div className="grid grid-cols-1 lg:grid-cols-2 xl:grid-cols-3 gap-[60px] mt-[100px]">
                    {posts.map((post) => (
                        <div className="my-[25px]">
                            <HashLink to={`/blog/${post.id}`}>
                                <Card
                                    image={post.attributes.thumbnail.data.attributes.url}
                                    title={post.attributes.title}
                                    description={post.attributes.shortContent}
                                    createdAt={post.attributes.createdAt}
                                />
                            </HashLink>
                        </div>
                    ))}
                </div>
            </div>
        </section>
    )
}

export default Blog;
