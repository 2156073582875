import Form from "./form/Form";
import Info from "./info/Info";

const Contact = () => {
    return (
        <section id="kontakt">
            <div className="relative -bottom-[150px] container mx-auto grid grid-cols-1 lg:grid-cols-2 ">
                <div className="z-[50]">
                    <Form/>
                </div>
                <div className="order-first lg:order-last">
                    <Info/>
                </div>
            </div>
        </section>
    )
}

export default Contact;