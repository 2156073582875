import image from "../../../../assets/onasnowe.JPG"

const Right = () => {
    return (
        <div className="w-full h-full my-[25px">
            <img src={image} alt="About Us" className="object-cover h-full w-full"/>
        </div>
    )
}

export default Right;